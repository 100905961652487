<template>
  <header class="pt-4 text-left">
    <div class="flex px-5 w-full justify-between items-center">
      <!-- Logo -->
      <img :src="logoSrc" alt="" :style="logoStyle" />

      <!-- Company name -->
      <span :class="[isMobile ? 'text-sm px-2 py-1' : 'py-2 px-3']" class="border border-gray-300 bg-white rounded-full font-bold float-right mb-3">
        {{ companyName }}
      </span>
    </div>
  </header>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "WelcomeHeaderComponent",
  setup() {
    const logoSrc = "./../logo.png",
      route = useRoute(),
      companyName = ref("PT. Petrokimia Gresik"),
      isMobile = ref(false);

    const checkScreenSize = () => {
      isMobile.value = window.innerWidth <= 768; // Threshold for mobile
    };

    onMounted(() => {
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize); // Update on window resize
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkScreenSize);
    });

    const containerClass = computed(() => ({
      flex: true,
      "items-center": true,
      "justify-between": true,
      "px-5": true,
      "w-full": true,
      "flex-row": !isMobile.value,
      "flex-col": isMobile.value,
    }));

    const logoStyle = computed(() => ({
      height: isMobile.value ? "30px" : "40px", // Adjust logo size based on screen size
      width: "auto",
      marginBottom: "10px", // Add some space below the logo
    }));

    const companyNameClass = computed(() => ({
      border: true,
      "border-gray-300": true,
      "bg-white": true,
      "rounded-full": true,
      "py-2": true,
      "px-3": true,
      "font-bold": true,
      "text-sm": isMobile.value,
      "text-base": !isMobile.value,
    }));

    return {
      logoSrc,
      companyName,
      containerClass,
      logoStyle,
      companyNameClass,
      isMobile,
    };
  },
};
</script>
