<template>
  <div><p class="text-sm font-semibold leading-8">‎</p></div>
  <div><p class="text-sm font-semibold leading-8">‎</p></div>
  <LineChartLoader />
  <div><p class="text-sm font-semibold leading-8">‎</p></div>
  <div><p class="text-sm font-semibold leading-8">‎</p></div>
  <TableLoader />
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import LineChartLoader from "./LineChartLoader.vue";
import TableLoader from "./TableLoader.vue";

export default {
  name: "ContentLoaderComponent",
  components: {
    ContentLoader,
    LineChartLoader,
    TableLoader,
  },

  data() {
    return {};
  },
};
</script>
