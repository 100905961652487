<template>
  <MenuList :initialMenuList="menuList" />

  <!-- Notification -->
  <transition name="fade">
    <div v-if="notification.visible" :class="['notification', notification.success ? 'success' : notification.loading ? 'loading' : 'error']">
      <div class="notification-icon">
        <svg v-if="notification.success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="notification-svg">
          <path d="M9 16.172l-4.586-4.586a2 2 0 0 1 0-2.828l1.414-1.414a2 2 0 0 1 2.828 0L9 11.586l5.172-5.172a2 2 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.828L9 16.172z" />
        </svg>
        <svg v-else-if="notification.loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="notification-svg">
          <circle cx="11" cy="11" r="7" stroke-width="2" stroke="currentColor" fill="none" />
          <line x1="16.5" y1="16.5" x2="22" y2="22" stroke-width="2" stroke="currentColor" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="notification-svg">
          <path d="M12 2v20M2 12h20" />
        </svg>
      </div>
      <div class="notification-message">{{ notification.message }}</div>
      <button class="notification-close" @click="notification.visible = false">×</button>
    </div>
  </transition>

  <!-- Environment Section -->
  <section class="h-full text-center text-black flex flex-col items-center px-5 border-b border-gray-300">
    <div class="w-full my-3">
      <div class="flex justify-between items-center">
        <h3 class="text-left"><strong>Environment</strong></h3>
        <p class="ml-2 text-gray-500 text-sm">Last Update: {{ lastUpdate }}</p>
      </div>
      <ul class="mt-4 mb-5 flex flex-wrap gap-4 justify-start list-none bg-white" data-tabs-toggle="#tab-content" role="tablist">
        <li v-for="(item, index) in environtment" :key="index" class="relative w-60 gap-3 sm:w-48 py-2 px-3 bg-gray-50 border border-gray-300 rounded min-h-16" role="presentation">
          <p class="text-xs mb-1">{{ item.name }}</p>
          <h5 :class="[item.isGreaterThan ? 'text-green-500' : 'text-red-500']" class="text-sm font-medium">
            {{ item.value }} <sup class="satuan-label">{{ item.satuan }}</sup>
          </h5>
        </li>
      </ul>
    </div>
  </section>

  <!-- Chart and Filters Section -->
  <section class="h-full text-center text-black flex flex-col items-center px-5">
    <div class="w-full my-3">
      <div :class="['flex', isMobile ? 'flex-col items-center' : 'justify-between']">
        <!-- Dropdown -->
        <select :class="['my-form-select2 bg-white', isMobile ? 'w-80 mb-4' : 'mx-0 mb-0 w-40']" v-model="A" @change="onDropdownChange">
          <option v-for="option in modelOptions" :key="option.id" :value="option.name">
            {{ option.name }}
          </option>
        </select>

        <div v-if="!isMobile" class="flex flex-row justify-end mt-3 mb-2">
          <!-- Date Filters -->
          <div v-for="(filter, index) in env_date_filter" :key="index" class="mr-1">
            <button :class="['border border-gray-200 rounded-full py-0.5 px-3', { 'bg-gray-400': index === p }]" type="button" @click="handleDateFilterClick(filter.value, index)">
              {{ filter.name }}
            </button>
          </div>
        </div>

        <div v-if="isMobile" class="flex flex-row mt-3 mb-2 w-full justify-center">
          <!-- Date Filters for Mobile -->
          <div v-for="(filter, index) in env_date_filter" :key="index" class="mb-2">
            <button :class="['border border-gray-200 rounded-full py-0.5 px-3', { 'bg-gray-400': index === p }]" type="button" @click="handleDateFilterClick(filter.value, index)">
              {{ filter.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div v-if="iscontentLoad" class="content-wrapper">
    <ContentLoaderComponent />
  </div>
  <div v-else>
    <!-- Chart Section -->
    <section class="h-full text-center text-black flex flex-col items-center px-5">
      <div class="w-full my-3">
        <div :class="{ loading: m }">
          <ApexChart :options="chartOptions" :series="S" height="400" type="line" width="100%" />
        </div>
      </div>
    </section>

    <!-- Summary Section -->
    <section class="h-full text-center text-black flex flex-col items-center px-5 border-b border-gray-300">
      <div class="w-full my-3">
        <div :class="{ loading: isLoading }">
          <ul class="my-white-list mt-4 mb-5 list-none bg-white border border-gray-300 rounded-lg shadow-md">
            <!-- Header Row -->
            <li class="w-full text-left p-3 border-b border-gray-300 flex justify-between bg-blue-500 rounded-t-lg">
              <div class="w-full grid grid-cols-8 gap-4 justify-between">
                <div v-if="isMobile" class="font-bold text-gray-700 flex items-center ml-2 text-sm">Param</div>
                <div v-if="!isMobile" class="font-bold text-gray-700 flex items-center ml-2">Parameter</div>
                <div v-for="(item, index) in envSummResult" :key="index" class="font-bold text-gray-700 flex items-center">
                  <span
                    :class="{
                      'text-sm ml-4': isMobile,
                      'ml-8': item.name === 'WL_Mid',
                      'ml-16': item.name === 'WL_High',
                    }"
                    v-if="isMobile"
                  >
                    {{ item.name }}
                  </span>
                  <span v-if="!isMobile">
                    {{ item.name }}
                  </span>
                </div>
              </div>
            </li>

            <!-- Data Rows -->
            <li v-for="(item, index) in envSumm" :key="index" class="w-full text-left p-3 border-b border-gray-300 flex justify-between hover:bg-gray-100 transition">
              <div class="w-full grid grid-cols-8 gap-4 justify-between">
                <div class="font-semibold text-gray-700 flex items-center ml-2">
                  <svg v-if="isMobile" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="getIconPath(item.name)" />
                  </svg>
                  <svg v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="getIconPath(item.name)" />
                  </svg>
                  <span v-if="isMobile" class="text-sm">{{ item.value }}</span>
                  <span v-if="!isMobile">{{ item.value }}</span>
                </div>
                <div v-if="isMobile" class="font-semibold text-gray-700 text-sm ml-8">{{ item.wl_low }}</div>
                <div v-if="!isMobile" class="font-semibold text-gray-700 ml-2">{{ item.wl_low }}</div>
                <div v-if="isMobile" class="font-semibold text-gray-700 text-sm ml-16">{{ item.wl_mid }}</div>
                <div v-if="!isMobile" class="font-semibold text-gray-700 ml-2">{{ item.wl_mid }}</div>
                <div v-if="isMobile" class="font-semibold text-gray-700 text-sm ml-20">{{ item.wl_hig }}</div>
                <div v-if="!isMobile" class="font-semibold text-gray-700 ml-2">{{ item.wl_hig }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ApexChart from "vue3-apexcharts";
import MenuList from "@/components/MenuList.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { ContentLoader } from "vue-content-loader";
import ContentLoaderComponent from "@/components/ContentLoaderComponent.vue";

export default {
  name: "Faperta",
  components: {
    MenuList,
    ApexChart,
    ContentLoader,
    ContentLoaderComponent,
  },

  setup() {
    const url1 = "https://inamas.id/dev/isolated/faperta/?sensor",
      url2 = "https://inamas.id/dev/petro/glasshouse/?setpoint",
      url3 = "https://inamas.id/dev/petro/glasshouse/?getdata&start=2024-08-07T00:00:00.000Z&&start=2024-08-08T00:00:00.000Z&limit=1000",
      modelOptions = ref([{ id: 1, name: "Environment" }]),
      isLoading = ref(false),
      iscontentLoad = ref(false), // Loading
      notification = ref({
        visible: false,
        message: "",
        success: false,
        loading: false,
      }),
      m = ref(false),
      p = ref(0),
      x = ref("1h"),
      W = ref({}), // Chart
      S = ref([]),
      A = ref("Environment"),
      lastUpdate = ref(""),
      isMobile = ref(false);

    let lastReadingTime = ref(null),
      templimitdown,
      templimitup,
      humlimitdown,
      humlimitup,
      sensorDataGrup = {
        "1h": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
        "3h": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
        "12h": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
        "1d": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
        "1w": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
        "1m": { K1: [], T1: [], K2: [], T2: [], K3: [], T3: [] },
      },
      sensorDataGrupDummy = {
        "1h": [
          { wl_low: "89", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
        ],
        "3h": [
          { wl_low: "89", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
          { wl_low: "85", wl_mid: "50", wl_hig: "80", created_at: "2024-08-19T09:00:00Z" },
          { wl_low: "87", wl_mid: "52", wl_hig: "82", created_at: "2024-08-19T08:00:00Z" },
          { wl_low: "84", wl_mid: "49", wl_hig: "78", created_at: "2024-08-19T07:00:00Z" },
          { wl_low: "86", wl_mid: "51", wl_hig: "81", created_at: "2024-08-19T06:00:00Z" },
          { wl_low: "83", wl_mid: "48", wl_hig: "77", created_at: "2024-08-19T05:00:00Z" },
        ],
        "12h": [
          { wl_low: "89", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
          { wl_low: "85", wl_mid: "50", wl_hig: "80", created_at: "2024-08-19T09:00:00Z" },
          { wl_low: "87", wl_mid: "52", wl_hig: "82", created_at: "2024-08-19T08:00:00Z" },
          { wl_low: "84", wl_mid: "49", wl_hig: "78", created_at: "2024-08-19T07:00:00Z" },
          { wl_low: "86", wl_mid: "51", wl_hig: "81", created_at: "2024-08-19T06:00:00Z" },
          { wl_low: "83", wl_mid: "48", wl_hig: "77", created_at: "2024-08-19T05:00:00Z" },
          { wl_low: "80", wl_mid: "45", wl_hig: "75", created_at: "2024-08-18T21:00:00Z" },
          { wl_low: "82", wl_mid: "47", wl_hig: "78", created_at: "2024-08-18T18:00:00Z" },
          { wl_low: "79", wl_mid: "44", wl_hig: "73", created_at: "2024-08-18T15:00:00Z" },
          { wl_low: "81", wl_mid: "46", wl_hig: "76", created_at: "2024-08-18T12:00:00Z" },
          { wl_low: "78", wl_mid: "43", wl_hig: "72", created_at: "2024-08-18T09:00:00Z" },
        ],
        "1d": [
          { wl_low: "89", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
          { wl_low: "85", wl_mid: "50", wl_hig: "80", created_at: "2024-08-19T09:00:00Z" },
          { wl_low: "87", wl_mid: "52", wl_hig: "82", created_at: "2024-08-19T08:00:00Z" },
          { wl_low: "84", wl_mid: "49", wl_hig: "78", created_at: "2024-08-19T07:00:00Z" },
          { wl_low: "86", wl_mid: "51", wl_hig: "81", created_at: "2024-08-19T06:00:00Z" },
          { wl_low: "83", wl_mid: "48", wl_hig: "77", created_at: "2024-08-19T05:00:00Z" },
          { wl_low: "80", wl_mid: "45", wl_hig: "75", created_at: "2024-08-18T21:00:00Z" },
          { wl_low: "82", wl_mid: "47", wl_hig: "78", created_at: "2024-08-18T18:00:00Z" },
          { wl_low: "79", wl_mid: "44", wl_hig: "73", created_at: "2024-08-18T15:00:00Z" },
          { wl_low: "81", wl_mid: "46", wl_hig: "76", created_at: "2024-08-18T12:00:00Z" },
          { wl_low: "78", wl_mid: "43", wl_hig: "72", created_at: "2024-08-18T09:00:00Z" },
          { wl_low: "76", wl_mid: "42", wl_hig: "70", created_at: "2024-08-18T00:00:00Z" },
          { wl_low: "77", wl_mid: "43", wl_hig: "71", created_at: "2024-08-17T18:00:00Z" },
          { wl_low: "75", wl_mid: "41", wl_hig: "69", created_at: "2024-08-17T12:00:00Z" },
          { wl_low: "78", wl_mid: "44", wl_hig: "73", created_at: "2024-08-17T06:00:00Z" },
          { wl_low: "74", wl_mid: "40", wl_hig: "68", created_at: "2024-08-16T00:00:00Z" },
        ],
        "1w": [
          { wl_low: "90", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
          { wl_low: "85", wl_mid: "50", wl_hig: "80", created_at: "2024-08-19T09:00:00Z" },
          { wl_low: "87", wl_mid: "52", wl_hig: "82", created_at: "2024-08-19T08:00:00Z" },
          { wl_low: "84", wl_mid: "49", wl_hig: "78", created_at: "2024-08-19T07:00:00Z" },
          { wl_low: "86", wl_mid: "51", wl_hig: "81", created_at: "2024-08-19T06:00:00Z" },
          { wl_low: "83", wl_mid: "48", wl_hig: "77", created_at: "2024-08-19T05:00:00Z" },
          { wl_low: "80", wl_mid: "45", wl_hig: "75", created_at: "2024-08-18T21:00:00Z" },
          { wl_low: "82", wl_mid: "47", wl_hig: "78", created_at: "2024-08-18T18:00:00Z" },
          { wl_low: "79", wl_mid: "44", wl_hig: "73", created_at: "2024-08-18T15:00:00Z" },
          { wl_low: "81", wl_mid: "46", wl_hig: "76", created_at: "2024-08-18T12:00:00Z" },
          { wl_low: "78", wl_mid: "43", wl_hig: "72", created_at: "2024-08-18T09:00:00Z" },
          { wl_low: "76", wl_mid: "42", wl_hig: "70", created_at: "2024-08-18T00:00:00Z" },
          { wl_low: "77", wl_mid: "43", wl_hig: "71", created_at: "2024-08-17T18:00:00Z" },
          { wl_low: "75", wl_mid: "41", wl_hig: "69", created_at: "2024-08-17T12:00:00Z" },
          { wl_low: "78", wl_mid: "44", wl_hig: "73", created_at: "2024-08-17T06:00:00Z" },
          { wl_low: "74", wl_mid: "40", wl_hig: "68", created_at: "2024-08-16T00:00:00Z" },
          { wl_low: "70", wl_mid: "38", wl_hig: "65", created_at: "2024-08-12T00:00:00Z" },
          { wl_low: "72", wl_mid: "39", wl_hig: "67", created_at: "2024-08-09T00:00:00Z" },
          { wl_low: "69", wl_mid: "37", wl_hig: "63", created_at: "2024-08-06T00:00:00Z" },
          { wl_low: "71", wl_mid: "38", wl_hig: "64", created_at: "2024-08-03T00:00:00Z" },
          { wl_low: "68", wl_mid: "36", wl_hig: "62", created_at: "2024-08-01T00:00:00Z" },
        ],
        "1m": [
          { wl_low: "90", wl_mid: "55", wl_hig: "85", created_at: "2024-08-19T12:00:00Z" },
          { wl_low: "92", wl_mid: "57", wl_hig: "88", created_at: "2024-08-19T11:50:00Z" },
          { wl_low: "88", wl_mid: "53", wl_hig: "82", created_at: "2024-08-19T11:40:00Z" },
          { wl_low: "91", wl_mid: "56", wl_hig: "87", created_at: "2024-08-19T11:30:00Z" },
          { wl_low: "89", wl_mid: "54", wl_hig: "84", created_at: "2024-08-19T11:20:00Z" },
          { wl_low: "85", wl_mid: "50", wl_hig: "80", created_at: "2024-08-19T09:00:00Z" },
          { wl_low: "87", wl_mid: "52", wl_hig: "82", created_at: "2024-08-19T08:00:00Z" },
          { wl_low: "84", wl_mid: "49", wl_hig: "78", created_at: "2024-08-19T07:00:00Z" },
          { wl_low: "86", wl_mid: "51", wl_hig: "81", created_at: "2024-08-19T06:00:00Z" },
          { wl_low: "83", wl_mid: "48", wl_hig: "77", created_at: "2024-08-19T05:00:00Z" },
          { wl_low: "80", wl_mid: "45", wl_hig: "75", created_at: "2024-08-18T21:00:00Z" },
          { wl_low: "82", wl_mid: "47", wl_hig: "78", created_at: "2024-08-18T18:00:00Z" },
          { wl_low: "79", wl_mid: "44", wl_hig: "73", created_at: "2024-08-18T15:00:00Z" },
          { wl_low: "81", wl_mid: "46", wl_hig: "76", created_at: "2024-08-18T12:00:00Z" },
          { wl_low: "78", wl_mid: "43", wl_hig: "72", created_at: "2024-08-18T09:00:00Z" },
          { wl_low: "76", wl_mid: "42", wl_hig: "70", created_at: "2024-08-18T00:00:00Z" },
          { wl_low: "77", wl_mid: "43", wl_hig: "71", created_at: "2024-08-17T18:00:00Z" },
          { wl_low: "75", wl_mid: "41", wl_hig: "69", created_at: "2024-08-17T12:00:00Z" },
          { wl_low: "78", wl_mid: "44", wl_hig: "73", created_at: "2024-08-17T06:00:00Z" },
          { wl_low: "74", wl_mid: "40", wl_hig: "68", created_at: "2024-08-16T00:00:00Z" },
          { wl_low: "70", wl_mid: "38", wl_hig: "65", created_at: "2024-08-12T00:00:00Z" },
          { wl_low: "72", wl_mid: "39", wl_hig: "67", created_at: "2024-08-09T00:00:00Z" },
          { wl_low: "69", wl_mid: "37", wl_hig: "63", created_at: "2024-08-06T00:00:00Z" },
          { wl_low: "71", wl_mid: "38", wl_hig: "64", created_at: "2024-08-03T00:00:00Z" },
          { wl_low: "68", wl_mid: "36", wl_hig: "62", created_at: "2024-08-01T00:00:00Z" },
          { wl_low: "65", wl_mid: "34", wl_hig: "60", created_at: "2024-07-19T00:00:00Z" },
          { wl_low: "67", wl_mid: "35", wl_hig: "62", created_at: "2024-07-19T00:00:00Z" },
          { wl_low: "64", wl_mid: "33", wl_hig: "58", created_at: "2024-06-19T00:00:00Z" },
          { wl_low: "66", wl_mid: "34", wl_hig: "61", created_at: "2024-06-19T00:00:00Z" },
          { wl_low: "63", wl_mid: "32", wl_hig: "57", created_at: "2024-05-19T00:00:00Z" },
        ],
      };

    const menuList = [
        { id: 1, title: "Value Setting", route: { name: "value-setting-fp" }, icon: "value-setting.svg" },
        { id: 2, title: "Device Detail", route: { name: "" }, icon: "device-detail.svg" },
        { id: 3, title: "Detail Info", route: { name: "", params: { id: "1" } }, icon: "detail-info.svg" },
        { id: 4, title: "Feature Setting", route: { name: "" }, icon: "feature-setting.svg" },
        { id: 5, title: "Export Data", route: { name: "" }, icon: "export-data.svg" },
      ],
      environtment = ref([
        { name: "Water Level Low", satuan: "", value: "OFF", isGreaterThan: false, isLowerThan: false },
        { name: "Water Level Mid", satuan: "", value: "OFF", isGreaterThan: false, isLowerThan: false },
        { name: "Water Level High", satuan: "", value: "OFF", isGreaterThan: false, isLowerThan: false },
      ]),
      env_date_filter = ref([
        { name: "1h", value: "1h" },
        { name: "3h", value: "3h" },
        { name: "12h", value: "12h" },
        { name: "1d", value: "1d" },
        { name: "1w", value: "1w" },
        { name: "1m", value: "1m" },
      ]),
      envSummResult = ref([
        { name: "WL_Low", value: null },
        { name: "WL_Mid", value: null },
        { name: "WL_High", value: null },
      ]),
      envSumm = ref([
        { name: "Max", value: "Max", wl_low: 100, wl_mid: 100, wl_hig: 100 },
        { name: "Min", value: "Min", wl_low: 100, wl_mid: 100, wl_hig: 100 },
        { name: "Avg", value: "Avg", wl_low: 100, wl_mid: 100, wl_hig: 100 },
        { name: "Day", value: "Day", wl_low: 100, wl_mid: 100, wl_hig: 100 },
      ]);

    const updateChart = (data) => {
        S.value = [];
        const val = [
          { name: "WL_Low", data: [] },
          { name: "WL_MID", data: [] },
          { name: "WL_HIGH", data: [] },
        ];
        data.forEach((item) => {
          const time = new Date(item.created_at).getTime();
          const timeUTCPlus7 = time + 7 * 60 * 60 * 1000;

          if (item.wl_low !== null) {
            val[0].data.push({ x: timeUTCPlus7, y: parseFloat(item.wl_low) });
          }
          if (item.wl_mid !== null) {
            val[1].data.push({ x: timeUTCPlus7, y: parseFloat(item.wl_mid) });
          }
          if (item.wl_hig !== null) {
            val[2].data.push({ x: timeUTCPlus7, y: parseFloat(item.wl_hig) });
          }
        });
        S.value = val;
      },
      onDropdownChange = (event) => {
        A.value = event.target.value;
      },
      handleDateFilterClick = (value, index) => {
        p.value = index;
        x.value = value;
        updateChart(sensorDataGrupDummy[x.value]);
        // updateSummaries(sensorDataGrup, value);
      },
      chartOptions = ref({
        chart: {
          type: "line",
          height: 400,
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value) {
              const date = new Date(value);
              return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, "0")}-${String(date.getUTCDate()).padStart(2, "0")} ${String(date.getUTCHours()).padStart(2, "0")}:${String(date.getUTCMinutes()).padStart(
                2,
                "0"
              )}`;
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy HH:mm",
          },
        },
      });

    const getIconPath = (name) => {
      switch (name) {
        case "Max":
          return "M5 12l7-7 7 7M12 5v14";
        case "Min":
          return "M19 12l-7 7-7-7M12 19V5";
        case "Avg":
          return "M4 12h16M4 6h16M4 18h16";
        case "Day":
          return "M12 2v2m0 16v2m-6.364-3.636l1.414 1.414M4.222 4.222l1.414 1.414m12.728 12.728l1.414 1.414m-1.414-12.728l1.414-1.414M12 6a6 6 0 110 12 6 6 0 010-12z";
        default:
          return "M12 2a10 10 0 110 20 10 10 0 010-20z";
      }
    };

    const fahrenheitToCelsius = (fahrenheit) => {
        if (typeof fahrenheit !== "number" || isNaN(fahrenheit)) {
          console.warn("Invalid temperature data:", fahrenheit);
          return null;
        }
        return parseFloat((((fahrenheit - 32) * 5) / 9).toFixed(2));
      },
      convertToUTCPlus7 = (utcTime) => {
        const utcPlus7 = new Date(utcTime + 7 * 60 * 60 * 1000);
        return utcPlus7.getTime();
      },
      formatDateToDDMMYYYY = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        return `${day}`;
      };

    async function getDatasetpoint(retries = 3, delay = 1000) {
      try {
        const response = await fetch(url2);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const percentValueTemp = (parseInt(data.advice.tempTh) * parseInt(data.advice.tempTol)) / 100;
        const percentValueHum = (parseInt(data.advice.humTh) * parseInt(data.advice.humTol)) / 100;
        templimitup = parseInt(data.advice.tempTh) + percentValueTemp;
        templimitdown = parseInt(data.advice.tempTh) - percentValueTemp;
        humlimitup = parseInt(data.advice.humTh) + percentValueHum;
        humlimitdown = parseInt(data.advice.humTh) - percentValueHum;
      } catch (error) {
        if (retries > 0) {
          notification.value = {
            visible: true,
            message: `Retrying getDatasetpoint... (${retries} retries left)`,
            success: false,
            loading: true,
          };
          await new Promise((res) => setTimeout(res, delay));
          return getDatasetpoint(retries - 1, delay * 5); // Increase delay for next retry
        } else {
          notification.value = {
            visible: true,
            message: "There was a problem with the fetch setpoint:" + error.message,
            success: false,
            loading: false,
          };
        }
      }
    }

    async function getDataEnvi(retries = 3, delay = 1000) {
      try {
        const response = await fetch(url1);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const advice = data.advice;
        updateEnvironmentData(advice);
        lastUpdate.value = new Date().toLocaleString();
      } catch (error) {
        if (retries > 0) {
          notification.value = {
            visible: true,
            message: `Retrying getDataEnvi... (${retries} retries left)`,
            success: false,
            loading: true,
          };
          await new Promise((res) => setTimeout(res, delay));
          return getDataEnvi(retries - 1, delay * 5); // Delay for next retry
        } else {
          notification.value = {
            visible: true,
            message: "There was a problem with the fetch environtment:" + error.message,
            success: false,
            loading: false,
          };
        }
      }
    }

    async function getDataSummary() {
      fetch(url3)
        .then((response) => response.json())
        .then((data) => {
          updateGrafikandSumm(data.sensors);
          iscontentLoad.value = false;
          notification.value = {
            visible: true,
            message: "Berhasil menerima data",
            success: true,
            loading: false,
          };
          setTimeout(() => {
            notification.value.visible = false;
          }, 2000);
        })
        .catch((error) => {
          notification.value = {
            visible: true,
            message: "Error fetching data:" + error.message,
            success: false,
            loading: false,
          };
          setTimeout(() => {
            notification.value.visible = false;
          }, 2000);
        });
    }

    const updateEnvironmentData = (advice) => {
      if (advice.wl_low === "1") {
        environtment.value[0].isGreaterThan = true;
        environtment.value[0].value = "ON";
      } else if (advice.wl_low === "0") {
        environtment.value[0].isGreaterThan = false;
        environtment.value[0].value = "OFF";
      }

      if (advice.wl_mid === "1") {
        environtment.value[1].isGreaterThan = true;
        environtment.value[1].value = "ON";
      } else if (advice.wl_mid === "0") {
        environtment.value[1].isGreaterThan = false;
        environtment.value[1].value = "OFF";
      }

      if (advice.wl_hig === "1") {
        environtment.value[2].isGreaterThan = true;
        environtment.value[2].value = "ON";
      } else if (advice.wl_hig === "0") {
        environtment.value[2].isGreaterThan = false;
        environtment.value[2].value = "OFF";
      }
    };

    function updateGrafikandSumm(sensors) {
      const sensorIds = Object.keys(sensors);
      const now = new Date();
      sensorIds.forEach((sensorId, index) => {
        const readings = sensors[sensorId];

        if (readings.length > 0) {
          readings.sort((a, b) => new Date(b.observed) - new Date(a.observed));

          readings.forEach((reading) => {
            const readingTime = new Date(reading.observed);
            const timeDifference = now - readingTime;
            const convertedTime = convertToUTCPlus7(new Date(reading.observed).getTime());
            const temperature = fahrenheitToCelsius(reading.temperature);
            const humidity = reading.humidity;

            if (timeDifference <= 1 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["1h"], index, convertedTime, temperature, humidity);
            }
            if (timeDifference <= 3 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["3h"], index, convertedTime, temperature, humidity);
            }
            if (timeDifference <= 12 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["12h"], index, convertedTime, temperature, humidity);
            }
            if (timeDifference <= 24 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["1d"], index, convertedTime, temperature, humidity);
            }
            if (timeDifference <= 7 * 24 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["1w"], index, convertedTime, temperature, humidity);
            }
            if (timeDifference <= 30 * 24 * 60 * 60 * 1000) {
              pushData(sensorDataGrup["1m"], index, convertedTime, temperature, humidity);
            }
          });
        }
      });
      updateChart(sensorDataGrup["1h"]);
      updateSummaries(sensorDataGrup, "1h");
      notification.value.visible = false;
    }

    function pushData(target, index, convertedTime, temperature, humidity) {
      if (index === 0) {
        target.K1.push({ x: convertedTime, y: temperature });
        target.T1.push({ x: convertedTime, y: humidity });
      } else if (index === 1) {
        target.K2.push({ x: convertedTime, y: temperature });
        target.T2.push({ x: convertedTime, y: humidity });
      } else if (index === 2) {
        target.K3.push({ x: convertedTime, y: temperature });
        target.T3.push({ x: convertedTime, y: humidity });
      }
    }

    function updateSummaries(sensorDataGrup, group) {
      envSumm.value.forEach((summary) => {
        if (summary.name === "Max") {
          summary.temp1 = Math.max(...sensorDataGrup[group].K1.map((d) => d.y)).toFixed(2);
          summary.rh1 = Math.max(...sensorDataGrup[group].T1.map((d) => d.y)).toFixed(2);
          summary.temp2 = Math.max(...sensorDataGrup[group].K2.map((d) => d.y)).toFixed(2);
          summary.rh2 = Math.max(...sensorDataGrup[group].T2.map((d) => d.y)).toFixed(2);
          summary.temp3 = Math.max(...sensorDataGrup[group].K3.map((d) => d.y)).toFixed(2);
          summary.rh3 = Math.max(...sensorDataGrup[group].T3.map((d) => d.y)).toFixed(2);
        } else if (summary.name === "Min") {
          summary.temp1 = Math.min(...sensorDataGrup[group].K1.map((d) => d.y)).toFixed(2);
          summary.rh1 = Math.min(...sensorDataGrup[group].T1.map((d) => d.y)).toFixed(2);
          summary.temp2 = Math.min(...sensorDataGrup[group].K2.map((d) => d.y)).toFixed(2);
          summary.rh2 = Math.min(...sensorDataGrup[group].T2.map((d) => d.y)).toFixed(2);
          summary.temp3 = Math.min(...sensorDataGrup[group].K3.map((d) => d.y)).toFixed(2);
          summary.rh3 = Math.min(...sensorDataGrup[group].T3.map((d) => d.y)).toFixed(2);
        } else if (summary.name === "Avg") {
          summary.temp1 = (sensorDataGrup[group].K1.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].K1.length).toFixed(2);
          summary.rh1 = (sensorDataGrup[group].T1.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].T1.length).toFixed(2);
          summary.temp2 = (sensorDataGrup[group].K2.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].K2.length).toFixed(2);
          summary.rh2 = (sensorDataGrup[group].T2.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].T2.length).toFixed(2);
          summary.temp3 = (sensorDataGrup[group].K3.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].K3.length).toFixed(2);
          summary.rh3 = (sensorDataGrup[group].T3.reduce((acc, val) => acc + val.y, 0) / sensorDataGrup[group].T3.length).toFixed(2);
        } else if (summary.name === "Day") {
          const lastReadingTime = new Date();
          const formattedDate = formatDateToDDMMYYYY(lastReadingTime);
          summary.temp1 = formattedDate;
          summary.rh1 = formattedDate;
          summary.temp2 = formattedDate;
          summary.rh2 = formattedDate;
          summary.temp3 = formattedDate;
          summary.rh3 = formattedDate;
        }
      });
    }

    const checkScreenSize = () => {
      isMobile.value = window.innerWidth <= 768; // Set true if width is <= 768px
    };

    async function updateDataenvi() {
      await getDatasetpoint();
      getDataEnvi();
      lastUpdate.value = new Date().toLocaleString();
    }

    onMounted(() => {
      checkScreenSize(); // Initial check
      window.addEventListener("resize", checkScreenSize); // Listen to window resize

      notification.value = {
        visible: true,
        message: "Fetching data...",
        success: false,
        loading: true,
      };
      updateChart(sensorDataGrupDummy["1h"]);
      getDataEnvi();
      notification.value = {
        visible: true,
        message: "Berhasil menerima data",
        success: true,
        loading: false,
      };
      setTimeout(() => {
        notification.value.visible = false;
      }, 2000);
      setInterval(() => {
        getDataEnvi();
      }, 1000);
      // setInterval(() => {
      //   getDataSummary();
      // }, 3600000); // 1000 milliseconds = 1 second
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkScreenSize);
    });

    return {
      A,
      m,
      p,
      S,
      W,
      x,
      envSumm,
      menuList,
      isMobile,
      isLoading,
      lastUpdate,
      getIconPath,
      updateChart,
      chartOptions,
      environtment,
      modelOptions,
      notification,
      envSummResult,
      iscontentLoad,
      updateDataenvi,
      env_date_filter,
      onDropdownChange,
      handleDateFilterClick,
    };
  },
};
</script>

<style scoped>
.bg-blue-500 {
  background-color: #def1e7;
}
</style>
